import { graphql, Link } from "gatsby";
import * as React from "react";
import { Layout } from "../../../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";

const WuerfePage = (props) => {
  const data = props.data.allWuerfeYaml.edges;
  return (
    <>
      <Layout title="Würfe">
        <main className="container">
          <h1 className="mb-5">Würfe</h1>
          <div className="row g-2 justify-content-evenly">
            {data.map(({ node }) => (
              <Link
                className="col-12 col-md-6 text-center d-block"
                to={`/zucht/wuerfe/${node.name}/`}
                style={{ textDecoration: "none" }}
              >
                <GatsbyImage
                  className="shadow image-link"
                  quality={80}
                  image={node.mainImage.childImageSharp.gatsbyImageData}
                  alt={node.fullName}
                  objectFit="contain"
                  loading="lazy"
                />
                <h2 className="py-3">{node.fullName}</h2>
              </Link>
            ))}
          </div>
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    allWuerfeYaml(sort: { fields: fullName, order: DESC }) {
      edges {
        node {
          fullName
          name
          mainImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;

export default WuerfePage;
